import './dependencies'
import './main'

import setupErrorReporting from './errorReporting'

// Do this first to catch later errors
setupErrorReporting()

import './forms'
import './overlay'
import './toggle'
import './tooltip'
import './utils'

import createModal from './create-modal'
import focusIndicator from './focus-indicator'
import { initFoldoutToggle } from './foldout-toggle'
import { initImageLightboxModal } from './lightbox-dialog'
import { initTimeago } from './timeago'

KUNDO.dependencies.addOnce([
  focusIndicator,
  createModal,
  initTimeago,
  function () {
    new KUNDO.utils.PermanentCloseLink($('.fn-permanent-close-trigger'))
  },
])

KUNDO.dependencies.addOnce([
  // fmt: expand
  KUNDO.overlay,
  initImageLightboxModal,
  initFoldoutToggle,
])

KUNDO.dependencies.add([
  // fmt: expand
  KUNDO.toggle,
  KUNDO.forms,
  KUNDO.tooltip,
])
